<template>
	<div class="container">

		<div class="doc">
			<div class="head1">Allgemeine Geschäftsbedienungen</div>
			
			<div class="item">
				<div class="head2"></div>
				<div class="cont">
					Allgemeine Geschäftsbedingungen (AGB) in Anlehnung an die für Unternehmen im Garten-, Landschafts- und Sportplatzbau (Landschaftsgärtner) vorgeschlagene Fassung der Bundesinnung für Gärtner und Floristen

				</div>
			</div>
			
			<div class="item">
				<div class="head2">1. Geltungsbereich</div>
				<div class="cont">
					Diese Allgemeinen Geschäftsbedingungen gelten für alle gegenwärtigen und künftigen Geschäftsbeziehungen zwischen dem Auftraggeber und dem Landschaftsgärtner (im Folgenden „Auftragnehmer“), das sind insbesondere alle Arbeiten, Lieferungen und sonstigen Leistungen durch Unternehmen im Garten-, Landschafts- und Sportplatzbau, soweit im Einzelfall keine abweichenden vertraglichen Vereinbarungen getroffen werden.
					Auf Verbraucher im Sinne des Konsumentenschutzgesetzes finden diese Geschäftsbedingungen Anwendung, soweit sie nicht zwingenden Regelungen des Konsumentenschutzgesetzes widersprechen.Die Informationspflicht für Verbraucher nach §5KSCHG erfüllen wir auf der Rückseite unseres Briefpapiers.
					Entgegenstehende Geschäftsbedingungen des Auftraggebers gelten selbst bei Kenntnis durch den Auftragnehmer nur dann, wenn sie vom Auftragnehmer ausdrücklich und schriftlich anerkannt und bestätigt werden.

				</div>
			</div>
			
			<div class="item">
				<div class="head2">2. Anbot</div>
				<div class="cont"> 
					Die Angebote des Auftragnehmers samt dazugehöriger Unterlagen sind, soweit nichts anderes festgelegt ist, freibleibend und unverbindlich und zwar hinsichtlich aller angegebenen Daten einschließlich des Honorars. Sämtliche technischen und sonstigen Unterlagen bleiben geistiges Eigentum des Auftragnehmers. Jede Verwendung, insbesondere die Weitergabe, Vervielfältigung und Veröffentlichung bedarf der ausdrücklichen Zustimmung des Auftragnehmers.
					Unser Online-Katalog ist kein Anbot, sondern ein Informationsservice für Kunden.

				</div>
			</div>
			
			<div class="item">
				<div class="head2">3. Vertragsabschluß</div>
				<div class="cont">
					Aufträge und Bestellungen verpflichten den Auftragnehmer erst nach dem Erhalt einer vom Auftraggeber unterzeichneten Auftragsbestätigung. Der Auftragnehmer kann jedoch vor Beginn der Vertragserfüllung oder während derselben vom Vertrag ohne Schadenersatzverpflichtung zurücktreten, wenn höhere Gewalt die Durchführung oder die Materialbeschaffung unmöglich macht.
					Die Vergabe des Auftrages an Subunternehmer bleibt dem Auftragnehmer vorbehalten.
					Änderungen, Ergänzungen oder Zusatzaufträge bedürfen der schriftlichen Auftragserteilung. Arbeiten, die zur ordnungsgemäßen Durchführung des Auftrages unbedingt notwendig bzw. unvermeidlich sind, jedoch ohne Verschulden des Auftragnehmers erst während der Arbeitsdurchführung erkannt werden, sind dem Auftraggeber unverzüglich zu melden.Sofern es dadurch zu einer Kostenüberschreitung von über 15% kommt, muss der Auftraggeber diese vor der Durchführung genehmigen. Nur dann ist er verpflichtet, diese zu bezahlen. Ansonsten kann der Auftraggeber aber aus diesem Grund vom Vertrag zurücktreten. In diesem Fall sind alle bisher geleisteten Arbeiten zu vergüten.
					Werden im Laufe der Durchführung der Arbeiten über das Angebot hinausgehende Arbeiten für zweckmäßig erkannt, so ist ebenfalls dem Auftraggeber unverzüglich Nachricht zu geben. Wenn der Auftraggeber diese Arbeiten genehmigt, gelten sie als Zusatzaufträge, die gesondert zu verrechnen sind.

				</div>
			</div>
			
			<div class="item">
				<div class="head2">4. Rücktrittsrecht für Verbraucher</div>
				<div class="cont">
					Hat der Verbraucher seine Vertragserklärung weder in unserem Schaugarten noch auf einer Messe abgegeben kann er binnen 14 Tagen formfrei vom Vertrag zurücktreten. Die Frist beginnt mit Ausfolgung einer Informationsurkunde, frühestens aber mit Zustandekommen des Vertrages.
					Ein Rücktrittsrecht nach $3KSCHG steht dem Verbraucher nicht zu, wenn er seine Vertragserklärung in körperlicher Abwesenheit des Unternehmers abgegeben hat (Auftragsbestätigung per Mail oder Brief).

				</div>
			</div>
			
			<div class="item">
				<div class="head2">5. Ausführung der Arbeiten</div>
				<div class="cont">
					Zur Ausführung der Leistung ist der Auftragnehmer erst nach Schaffung aller baulichen, technischen und rechtlichen Voraussetzungen durch den Auftraggeber verpflichtet.
					Vereinbarte Ausführungstermine gelten als Richtwerte. Bei Arbeiten, die von den Witterungsverhältnissen abhängig sind, kann es dadurch zu Verzögerungen kommen.
					Wasser, Strom und sonstige notwendigen Voraussetzungen für die Ausführung hat der Auftraggeber, wenn nicht ausdrücklich anderes vereinbart worden ist, kostenlos beizustellen.

				</div>
			</div>
			
			<div class="item">
				<div class="head2">6. Abnahme</div>
				<div class="cont">
					Der Auftragnehmer hat die Fertigstellung unverzüglich anzuzeigen. Es gilt auch der Zugang der Rechnung beim Auftraggeber als Anzeige der Fertigstellung.
					Eine Abnahmebesichtigung hat innerhalb von 8 Tagen zu erfolgen. Bei Fundamenten oder anderen später nicht mehr messbaren Ausführungen kann der Auftraggeber die Ausmaßkontrolle nur verlangen, solange die Ausmaße feststellbar sind. Die bei der Abnahmebesichtigung festgestellte Fertigstellung der Arbeiten und ihr Ausmaß hat der Auftraggeber dem Auftragnehmer unverzüglich zu bestätigen. Dies gilt auch für die vorzeitige Besichtigung von Fundamenten oder anderen, später nicht mehr messbaren Ausführungen.
					Pflanzen gelten am vereinbarten Tag ihrer Einpflanzung an den Auftraggeber als übernommen. Dies gilt auch bei Nichtanwesenheit des Auftraggebers.

				</div>
			</div>
			
			<div class="item">
				<div class="head2">7. Mängelrüge</div>
				<div class="cont">
					Für Lieferungen unter Unternehmern gilt § 377 UGB: Die Lieferungen und Leistungen des Auftragnehmers sind nach der Anzeige der Fertigstellung im Rahmen der Abnahmebesichtigung zu untersuchen. Mängel, die dabei festgestellt werden, sind unverzüglich nach der Abnahmebesichtigung schriftlich zu rügen. Später hervorkommende Mängel sind unverzüglich schriftlich anzuzeigen.
					Musste der Auftraggeber oder eine von ihm bestellte örtliche Bauleitung oder sonstige fachmännische Aufsicht während der Ausführung von Arbeiten oder bei der Lieferung von Pflanzen Mängel erkennen, so sind diese unverzüglich nach deren möglicher Entdeckung zu rügen.
					Erfolgt keine Abnahmebestätigung, so gilt die Leistung oder Lieferung als ordnungsgemäß übernommen, wenn der Auftraggeber nicht innerhalb von 8 Tagen nach Anzeige der Fertigstellung oder dem Zugang der Rechnung allfällige Mängel schriftlich gerügt hat. Wird eine Mängelrüge nicht oder nicht rechtzeitig erhoben, so gilt die Ware als genehmigt. Die Geltendmachung von Gewährleistungs-oder Schadenersatzansprüchen, sowie das Recht auf Irrtumsanfechtung, aufgrund von Mängeln sind dann ausgeschlossen.

				</div>
			</div>
			
			<div class="item">
				<div class="head2">8. Gewährleistung und Gewährleistungsfrist, Schadenersatz</div>
				<div class="cont">
					Der Auftragnehmer leistet Gewähr, dass seine Leistungen die im Vertrag ausdrücklich bedungenen bzw. sonst die gewöhnlich vorausgesetzten Eigenschaften haben und die Arbeiten sachgerecht und fachgerecht ausgeführt wurden.
					Falls Materialien und Pflanzen vom Auftraggeber beigestellt werden, erstreckt sich die Haftung des Auftragnehmers auf die fachgemäße Arbeit, nicht aber auf Ansprüche aus den beigestellten Pflanzen und Materialien.
					Mutterboden oder Humuslieferungen werden vom Auftragnehmer nur nach der äußeren Struktur und Beschaffenheit geprüft. Für hierbei nicht feststellbare Mängel, insbesondere im Nährstoffgehalt wie in der Schädlingsfreiheit, wird keine Haftung übernommen.
					Wenn der Auftragnehmer Pflanzen oder Saatgut liefert, so hat er Mängel, die darin bestehen, dass Pflanzen nicht anwachsen oder Saatgut nicht aufgeht, nur dann auf seine Kosten zu beseitigen, wenn ihm die Pflege für mindestens eine Vegetationsperiode, im allgemeinen für ein Jahr, übertragen wurde. Von dieser Verpflichtung ist er jedoch befreit, wenn die Schäden auf das seiner Einflussnahme entzogene Verhalten von Menschen, Haustieren, Wild, Weidevieh oder sonstiger äußerer Einflüsse oder auf ein starkes Auftreten von pflanzlichen oder tierischen Schädlingen zurückzuführen sind.
					Beim reinen Verkauf von Pflanzen verlieren wir die Kontrolle über die Pflegemaßnahmen, daher kann auch keine Gewährleistung für den Anwuchs übernommen werden.
					Treten Mängel auf, die der Auftragnehmer zu vertreten hat, so kann der Auftraggeber ihre Beseitigung verlangen, jedoch nur, wenn die Beseitigung keinen unverhältnismäßigen Aufwand erfordert. Sollte eine Beseitigung des Mangels sowohl durch Verbesserung als auch durch Austausch einer Lieferung/ Leistung möglich sein, entscheidet der Auftragnehmer, auf welche Art er den Gewährleistungsanspruch erfüllt. Wenn die Beseitigung einen unverhältnismäßigen Aufwand erfordern würde, kann der Auftraggeber nur verlangen, dass die Vergütung in angemessener Höhe herabgesetzt wird.
					Die Gewährleistungsfrist beträgt 3 Jahre ab Abnahme. Für Geschäfte zwischen Unternehmern wird die Beweislastumkehr des § 924 ABGB ausgeschlossen. Für Schäden oder Verzögerungen, die dem Auftraggeber durch höhere Gewalt oder Dritte entstehen, entfällt jegliche Haftung, auch während der Ausführung der Arbeiten. Für alle anderen Schäden, ausgenommen Personenschäden, haftet der Auftragnehmer nur bei Vorsatz oder grober Fahrlässigkeit. Bei Geschäften zwischen Unternehmern ist das Vorliegen von grober Fahrlässigkeit vom Geschädigten zu beweisen.

				</div>
			</div>
			
			<div class="item">
				<div class="head2">9. Rechnungslegung und Zahlung</div>
				<div class="cont">
					Mit den vereinbarten Preisen werden alle vertraglich vereinbarten Lieferungen und Leistungen abgegolten, sofern vertraglich nichts anderes vereinbart wurde.
					Mangels abweichender vertraglicher Vereinbarung erfolgt die Verrechnung nach der tatsächlich geleisteten Arbeitszeit bzw. der bei der Abnahme festgestellten Mengenermittlung. Leistungen, die im Anbot nicht ausdrücklich angeführt sind, sowie Änderungen, Ergänzungen oder Zusatzaufträge, werden aufgrund der aufgewendeten Arbeitszeit und der damit verbundenen Lieferungen nach den üblichen Verrechnungssätzen berechnet. Teilrechnungen oder Abschlagszahlungen aufgrund von Teilrechnungen oder Teilaufstellungen sind unverzüglich zu bezahlen. Schlussrechnungen sowie saisonmäßige Abschlussrechnungen sind binnen 14 Tagen ohne jeden Abzug zu bezahlen. Skontoabzüge sind, soweit sie nicht ausdrücklich vereinbart werden, unzulässig. Die Höchstsumme eines Haftrücklasses darf 3 % der Auftragssumme nicht übersteigen. Der Auftragnehmer ist berechtigt, den Haftrücklass durch eine Bankgarantie zu ersetzen. Zum Abzug eines Haftrücklasses ist eine ausdrückliche schriftliche Vereinbarung bei Vertragsabschluss erforderlich. Bei Zahlungsverzug des Auftraggebers ist der Auftragnehmer berechtigt, Verzugszinsen in der Höhe von mindestens 6 % über der jeweiligen Bankrate zu berechnen.

				</div>
			</div>
			
			<div class="item">
				<div class="head2">10. Eigentumsvorbehalt</div>
				<div class="cont">
					Bis zur vollständigen Bezahlung des Rechnungsbetrages bleiben sämtliche Lieferungen, soweit sie ohne Zerstörung oder Veränderung ihrer Wesensart entfernt werden können, im Eigentum des Auftragnehmers. Der Auftragnehmer darf daher auf Kosten des Auftraggebers nach Überschreitung des vorgesehenen Zahlungszieles und nach vorheriger schriftlicher Androhung der Ausübung des Eigentumsvorbehaltes die Lieferung entfernen. Allfällige, darüber hinausgehende Schadenersatzansprüche bleiben unberührt.
									</div>
			</div>	

			<div class="item">
				<div class="head2">11. Schiedsgutachten und Gerichtsstand</div>
				<div class="cont">
					Bei Meinungsverschiedenheiten zwischen dem Auftragnehmer und dem Auftraggeber über Fragen fachlicher Art ist das Schiedsgutachten eines Sachverständigen, der auf Antrag eines der Streitteile von der Wirtschaftskammer des Bundeslandes, in dem der Auftragnehmer seinen Unternehmenssitz hat, aus der Liste der ständig gerichtlich beeideten Sachverständigen zu bestellen ist, bindend. Die Kosten des Gutachtens trägt jener Teil, dessen Meinung unterliegt, im Zweifelsfalle werden die Kosten von den Streitteilen je zur Hälfte getragen. Auf die Rechtsbeziehungen zwischen den Vertragsparteien ist ausschließlich österreichisches Recht anwendbar. Die Anwendbarkeit des UN-Kaufrechts wird ausgeschlossen. Zur Entscheidung aller aus diesem Vertrag entstehenden Streitigkeiten ist dasjenige sachlich zuständige Gericht örtlich zuständig, in dessen Sprengel die Leistungserfüllung erfolgte, sofern keine andere vertragliche Vereinbarung vorliegt oder zwingende gesetzliche Regelungen nichts anderes bestimmen.

				</div>
			</div>

		</div> <!-- end doc -->
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'AGB',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">
div.doc {
	max-width: 50rem;
	margin: 0 auto;
	@media (max-width: 600px) {
		max-width: 22rem;
	}


	& div.head1 {
		text-align: center;
		color: gray;
		font-size: 1.2rem;
		font-weight: bold;
		margin: .6rem 0;
	}

	& div.head2 {
		text-align: left;
		color: var(--c12);
		font-size: 1.1rem;
		font-weight: bold;
		margin: 1.4rem 0 0rem 0;
	}
	
	& div.item {
		border-bottom: 1px solid #ddd;
		padding-bottom: 0.4rem;
		
		&:last-child {
			border:none;
		}
	}
}


</style>
